import { Injectable } from '@angular/core';
import * as api from '@dki/api-client';

import { MyRestaurantsActions, MyRestaurantsSelectors, RootStoreState, RouterStoreSelectors } from '@libs/dash/store';
import { select, Store } from '@ngrx/store';
import { RestaurantSelectServiceProvider } from './restaurant-select-facade.provider.interface';

@Injectable()
export class RestaurantSelectFacade implements RestaurantSelectServiceProvider {
	currentLanguage$ = this._store.pipe(select(RouterStoreSelectors.getCurrentLanguageParam));
	myDefaultRestaurant$ = this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurant));
	restaurantIsOpened$ = this._store.pipe(select(MyRestaurantsSelectors.restaurantIsOpened));

	isLoading$ = this._store.pipe(select(MyRestaurantsSelectors.getIsLoading));

	restaurantsList$ = this._store.pipe(select(MyRestaurantsSelectors.getListOfMyRestaurants));

	selectedRestaurant$ = this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurant));
	selectedRestaurants$ = this._store.pipe(select(MyRestaurantsSelectors.getSelectedRestaurants));

	multiSelectionEnabled$ = this._store.pipe(select(MyRestaurantsSelectors.isMultiSelectionEnabled));

	browserStorageChecked = false;

	constructor(private _store: Store<RootStoreState>) {}

	persistRestaurant(restaurantId: string | string[], multi: boolean, restaurantObject: api.Restaurant | api.Restaurant[]) {
		if (!multi) {
			localStorage.setItem('fav_restaurant', restaurantId as string);
			localStorage.setItem('fav_restaurant_object', JSON.stringify(restaurantObject));
		} else {
			localStorage.setItem('fav_restaurants', JSON.stringify(restaurantId));
			localStorage.setItem('fav_restaurants_object', JSON.stringify(restaurantObject));
		}
	}

	getPersistedRestaurant(): {
		favoriteRestaurant: string;
		favoriteRestaurants: string[];
	} {
		const favoriteRestaurant = localStorage.getItem('fav_restaurant');
		const favoriteRestaurants = JSON.parse(localStorage.getItem('fav_restaurants'));
		return { favoriteRestaurant, favoriteRestaurants };
	}

	setRestaurant(restaurant: api.Restaurant) {
		this._store.dispatch(MyRestaurantsActions.setRestaurant({ mySelectedRestaurant: restaurant }));
	}

	setMultiRestaurants(restaurants: api.Restaurant[]) {
		this._store.dispatch(MyRestaurantsActions.setMultiRestaurants({ mySelectedRestaurants: restaurants }));
	}
}
